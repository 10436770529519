import DynamicQuestionMixin from './dynamic-question';

export function CompletableSimMixin() {
  const dynamicMixin = DynamicQuestionMixin();

  dynamicMixin.computed.inputRoot = function () {
    return {
      completed: true,
    };
  };

  dynamicMixin.computed.inputRootKeys = function () {
    return ['completed'];
  };

  return dynamicMixin;
}
