






















import {loadScriptOnce, loadStylesOnce} from '@/loading/utils/dynamic-loading';
import SimPlaceholder from '@/loading/components/SimPlaceholder.vue';
import SimWrapper from '@/tasks/components/simulations/SimWrapper.vue';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watchEffect,
} from '@vue/composition-api';
import {extractNamespaceFromScriptSrc} from '@/tasks/components/simulations/utils/extractNamespaceFromScriptSrc';

export default defineComponent({
  name: 'LabSim',
  components: {SimPlaceholder, SimWrapper},
  inheritAttrs: false,
  props: {
    scriptSrc: {
      type: String as PropType<string | null>,
      default: '/simulations/stable/stemble-lab.min.js',
    },
    stylesSrc: {
      type: String as PropType<string | null>,
      default: null,
    },
    labName: {
      type: String as PropType<string | null>,
      default: null,
    },
    seed: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  setup(props, {emit}) {
    const scriptLoaded = ref(false);
    const customElementExists = ref(false);
    const resolvedElementName = ref(props.labName);
    const isNewSim = ref(false);

    const elementNameIsValid = computed(() => /^[-a-z0-9]+$/.test(props.labName ?? ''));

    function checkCustomElement() {
      if (!props.labName || !elementNameIsValid.value || !scriptLoaded.value) {
        return;
      }

      // Assume new-style lab and check namespaced version first
      const namespace = extractNamespaceFromScriptSrc(props.scriptSrc ?? '');
      if (namespace) {
        const namespacedLabName = `${namespace}-${props.labName}`;
        if (window.customElements?.get(namespacedLabName)) {
          customElementExists.value = true;
          resolvedElementName.value = namespacedLabName;
          isNewSim.value = true;
          return;
        }
      }

      // Fallback to existing custom element
      if (window.customElements?.get(props.labName)) {
        customElementExists.value = true;
        resolvedElementName.value = props.labName;
      }
    }

    watchEffect(checkCustomElement);

    onMounted(() => {
      const promises: Promise<any>[] = [];

      if (props.scriptSrc) {
        promises.push(loadScriptOnce(props.scriptSrc));
      }

      if (props.stylesSrc) {
        promises.push(loadStylesOnce(props.stylesSrc));
      }

      Promise.all(promises).then(() => {
        scriptLoaded.value = true;
        emit('loaded');
      });
    });

    return {
      isNewSim,
      scriptLoaded,
      elementNameIsValid,
      resolvedElementName,
      customElementExists,
    };
  },
});
